import { ApolloProvider } from "@apollo/client";
import { useApollo } from "../lib/apolloClient";
import { CookiesProvider } from "react-cookie";

import "../styles/globals.css";

function MyApp({ Component, pageProps }) {
  const apolloClient = useApollo(pageProps.initialApolloState);
  return (
    <ApolloProvider client={apolloClient}>
      <CookiesProvider>
        <Component {...pageProps} />
      </CookiesProvider>
    </ApolloProvider>
  );
}

export default MyApp;
